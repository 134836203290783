<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label="关键字:">
                        <el-input v-model="filters.keyWord" @input="(e) => (filters.keyWord = utils.validForbid(e))" placeholder="搜索关键词" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="省份:">
                        <el-select v-model="filters.provinceCode" @change="changeSearchProvince" filterable clearable>
                            <el-option v-for="item in provinceSearchOptions" :key="item.id" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="城市:">
                        <el-select v-model="filters.cityCode" @change="changeSearchCity" filterable clearable>
                            <el-option v-for="item in citySearchOptions" :key="item.id" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="区域:">
                        <el-select v-model="filters.regionCode" filterable clearable>
                            <el-option v-for="item in regionSearchOptions" :key="item.id" :label="item.name"
                                :value="item.code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="专家类型:">
                        <el-select v-model="filters.expertType" clearable>
                            <el-option v-for="item in expertOptions" :key="item.id" :label="item.value"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否再审项目:">
                        <el-select v-model="filters.isretrialItems" clearable>
                            <el-option v-for="item in options" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否有资质章:">
                        <el-select v-model="filters.isChapter" clearable>
                            <el-option v-for="item in options" :key="item.value" :label="item.name"
                                :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <!-- 列表 -->
        <el-table :data="tableData" border style="" v-loading="listLoading" ref="multipleTable"
            @current-change='currentChange' :highlight-current-row='true'>
            <el-table-column type='index' label="序号" width='60' align="center" :index='indexMethod'></el-table-column>
            <el-table-column prop="userName" label="姓名" align="center" width="230"></el-table-column>
            <el-table-column prop="phone" label="手机号" align="center" show-overflow-tooltip width="130"></el-table-column>
            <el-table-column prop="provinceName" label="所属地" align="center" width="220">
                <template slot-scope='scope'>
                    <span>{{ scope.row.provinceName + scope.row.cityName + scope.row.regionName }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="expertTypeName" label="专家类型" align="center" width="150"></el-table-column>
            <el-table-column prop="isChapter" label="是否有资质章" align="center" width="130">
                <template slot-scope='scope'>
                    <el-tag v-if="scope.row.isChapter == 0" type="danger">否</el-tag>
                    <el-tag v-if="scope.row.isChapter == 1" type="success">是</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="ddExpertExperienceType" label="是否有经验" align="center" width="130">
                <template slot-scope='scope'>
                    <el-tag v-if="scope.row.ddExpertExperienceType == 173" type="success">有经验</el-tag>
                    <el-tag v-if="scope.row.ddExpertExperienceType == 174" type="danger">无经验</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="reviewItemsCount" label="当前在审项目数"  align="center" width="200">
				<template slot-scope='scope'>
					<el-button @click="viewsProjectDetail(scope.row)" type="text">{{scope.row.reviewItemsCount}}</el-button>
				</template>
			</el-table-column>
            <el-table-column prop="expertiseList" label="特长" align="center" show-overflow-tooltip>
                <template slot-scope='scope'>
                    <span v-for="(item, index) in scope.row.expertiseList" :key="index">{{ item.expertiseName }}</span>
                </template>
            </el-table-column>
        </el-table>
        <!--底部工具条-->
        <el-col :span="24" class="pageBar">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="pages.pageIndex" :page-sizes="pages.pageArr" :page-size="pages.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="pages.dataCount">
            </el-pagination>
        </el-col>
        <!-- 新建弹窗 -->
        <el-dialog :title="dialogTitle" :visible.sync="addDialogShow" v-model="addDialogShow"
            :close-on-click-modal="false" width='50%'>
            <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="160px" style='min-width="800px"'>
                <el-form-item label="姓名:" prop="name">
                    <el-autocomplete v-model="addForm.name" value-key="name" @select="handleSelect"
                        :fetch-suggestions="querySearchAsync"></el-autocomplete>
                </el-form-item>
                <el-form-item label="账号:" prop="phone">
                    {{ addForm.phone }}
                </el-form-item>
                <el-form-item label="省份:" prop="provinceCode">
                    <el-select @change="changeProvince" v-model="addForm.provinceCode">
                        <el-option v-for="item in provinceOptions" :key="item.id" :label="item.name"
                            :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="城市:" prop="cityCode">
                    <el-select @change="changeCity" v-model="addForm.cityCode">
                        <el-option v-for="item in cityOptions" :key="item.id" :label="item.name"
                            :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区域:" prop="regionCode">
                    <el-select v-model="addForm.regionCode">
                        <el-option v-for="item in regionOptions" :key="item.id" :label="item.name"
                            :value="item.code"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="专家类型:" prop="ddId">
                    <el-select v-model="addForm.ddId">
                        <el-option v-for="item in expertOptions" :key="item.id" :label="item.value"
                            :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否有资质章:" prop="isChapter">
                    <el-select v-model='addForm.isChapter' clearable>
                        <el-option v-for="item in options" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否有经验:" prop="ddExpertExperienceType">
                    <el-select v-model='addForm.ddExpertExperienceType'>
                        <el-option v-for="item in optionsExpType" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="特长:">
                    <el-select v-model="addForm.expertExpertiseId" multiple>
                        <el-option v-for="item in specialtyOptions" :key="item.id" :label="item.value" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="addDialogClose">取消</el-button>
                <el-button type="primary" @click="addSubmit" :loading="addLoading">确认</el-button>
            </div>
        </el-dialog>
        <!-- 删除弹窗 -->
        <el-dialog
        title="确认删除"
        :visible.sync="handleDeleteVisible"
        v-model="handleDeleteVisible"
        :close-on-click-modal="false"
        >
            <el-form label-width="150px">
                <el-form-item>
                    确认删除该图审人员吗？
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click.native="handleDeleteVisible = false">取消</el-button>
                <el-button @click.native="confirmDelete" :loading="deleteLoading" type="primary">确认</el-button>
            </div>
        </el-dialog>
		<!-- 任务明细 -->
		<el-dialog
		    title="任务明细"
		    :visible.sync="ProjectDetailDialog"
		    v-model="ProjectDetailDialog"
		    :close-on-click-modal="false"
		    width='60%'
		>
		    <el-table
		        :data="ProjectDetailData"
		        border
		        style="width: 100%"
		        v-loading="ProjectDetailLoading"
		        ref="multipleTable"
		        :highlight-current-row='true'
		        :cell-style="rowClass"
		    >
		        <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
		        <el-table-column prop="projectName"  label="项目名称" align="center" show-overflow-tooltip></el-table-column>
		        <el-table-column prop="taskStatusName" label="状态" width='120' align="center" show-overflow-tooltip></el-table-column>
		        <el-table-column prop="overdueDate" label="逾期时间" width='150' align="center" show-overflow-tooltip></el-table-column>
		    </el-table>
		    <div slot="footer" class="dialog-footer">
		        <el-button @click.native="ProjectDetailDialog = false">取消</el-button>
		    </div>
		</el-dialog>
    </div>
</template>

<script>
import util from "../../../util/date";
import Toolbar from "../../components/Toolbar";
import { getButtonList } from "../../promissionRouter";
import {
    getExpertDataDictRelationList,
    addExpertDataDictRelation,
    updateExpertDataDictRelation,
    deleteExpertDataDictRelation,
    getByNamespace,
    getAreaDictionaryList,
    getAreaDictionaryByCode,
    getAreaDictionaryById,
	getUnFinishTaskListByUser,
    getUserListPage
} from '@/api/api'
export default {
    components: { Toolbar },
    data() {
        return {
            utils: '',
            buttonList: [],
            needData: '',
            filters: {
                keyWord: '',
                provinceCode: undefined,
                cityCode: undefined,
                regionCode: undefined,
                expertType: undefined,  // 专家类型
                isretrialItems: undefined, // 是否再审项目
                isChapter: undefined, // 是否有资质章
            },
            tableData: [],
            listLoading: false,
            //关于分页的obj
            pages: {
                pageSize: 20,//默认每页条数
                pageArr: [10, 20, 30, 40],//默认每页条数选择
                pageIndex: 1, //默认进入页
                dataCount: 20, //默认总页数
            },
            // 新建
            addDialogShow: false,
            handleDeleteVisible: false,
            deleteLoading: false,
            dialogTitle: '',
            addLoading: false,
            addForm: {
                name: '',
                id: undefined,
                phone: '',
                ddId: undefined, //专家类型
                expertExpertiseId: [],
                isChapter: undefined,
                provinceCode: undefined,
                cityCode: undefined,
                regionCode: undefined,
                remarks: '',
                ddExpertExperienceType: 173, // 是否有经验
                userInfoId: undefined
            },
            addRules: {
                ddId: [{ required: true, message: '请选择专家类型', trigger: 'change' }],
                expertExpertiseId: [{ required: true, message: '请选择特长', trigger: 'change', type: 'array' }],
                isChapter: [{ required: true, message: '请选择', trigger: 'change' }],
                ddExpertExperienceType: [{ required: true, message: '请选择', trigger: 'change' }],
                provinceCode: [{ required: true, message: '请选择', trigger: 'change' }],
                cityCode: [{ required: true, message: '请选择', trigger: 'change' }],
                regionCode: [{ required: true, message: '请选择', trigger: 'change' }],
                userInfoId: [{ required: true, message: '请选择', trigger: 'change' }],
            },
            // options
            options: [
                {
                    name: '否',
                    value: false
                },
                {
                    name: '是',
                    value: true
                }
            ],
            optionsExpType: [
                {
                    name: '有经验',
                    value: 173
                },
                {
                    name: '无经验',
                    value: 174
                }
            ],
            expertOptions: [{"id":62,"namespace":"CommonService.OrgPermission.ExpertIdentity","key":0,"value":"驻场图审工程师","sort":1,"remark":""},{"id":63,"namespace":"CommonService.OrgPermission.ExpertIdentity","key":1,"value":"在线图审专家","sort":2,"remark":""},{"id":162,"namespace":"CommonService.OrgPermission.ExpertIdentity","key":5,"value":"技术部专家","sort":6,"remark":""},{"id":389,"namespace":"CommonService.OrgPermission.ExpertIdentity","key":6,"value":"见习工程师","sort":7,"remark":""}],//专家类型
            specialtyOptions: [], //特长
            userList: [],

            provinceSearchOptions: [],//搜索 省
            citySearchOptions: [],//搜索 市
            regionSearchOptions: [],//搜索 区
            provinceOptions: [],//省
            cityOptions: [],//市
            regionOptions: [],//区
			// 项目明细
			ProjectDetailDialog: false,
			ProjectDetailLoading: false,
			ProjectDetailData: [],

        }
    },
    watch: {

    },
    created() {
        this.utils = util
        let routers = window.localStorage.router
            ? JSON.parse(window.localStorage.router)
            : [];
        this.buttonList = getButtonList(this.$route.path, routers);
        this.getDataList();
        this.init();
    },
    methods: {
        callFunction(item) {
            if(item.func == "getDataList") {
                this.pages.pageIndex = 1
            }
            this[item.func].apply(this, item);
        },
        async init() {
            // 获取专家类型  获取所有省份 获取特长
            const initResult = await Promise.all([
                // getByNamespace({ namespace: 'CommonService.OrgPermission.ExpertIdentity' }),
                getAreaDictionaryList({ parentId: 1 }),
                getByNamespace({ namespace: 'CommonService.OrgPermission.ExpertExpertise' }),
            ])
            const [provinceRes, expertiseRes] = initResult;
            // if (identityRes.data.success) {
            //     this.expertOptions = identityRes.data.response;
            // }
            if (provinceRes.data.success) {
                this.provinceOptions = provinceRes.data.response;
                this.provinceSearchOptions = provinceRes.data.response.reverse();
            }
            if (expertiseRes.data.success) {
                this.specialtyOptions = expertiseRes.data.response;
            }
        },
        //根据code  查询id
        getIdByCode(code){
            getAreaDictionaryByCode({ code: code,isEnable : true  }).then(res => {
                var result = res.data
                if (result.success) {
                    return result.response.id;
                }
            })
        },
        // 省切换
        changeSearchProvince(code) {
            this.filters.cityCode = undefined;
            this.filters.regionCode = undefined;
            this.citySearchOptions = [];
            this.regionSearchOptions = [];
            getAreaDictionaryByCode({ code: code,isEnable : true  }).then(res => {
                var result = res.data
                if (result.success) {
                    var id = result.response.id;
                    getAreaDictionaryList({ parentId: id }).then(res => {
                        if (res.data.success) {
                            this.citySearchOptions = res.data.response.reverse();
                        }
                    })
                }
            })
        },
        // 弹窗省切换
        changeProvince(code,oo) {
            this.addForm.cityCode = undefined;
            this.addForm.regionCode = undefined;
            this.cityOptions = [];
            this.regionOptions = [];
            getAreaDictionaryByCode({ code: code,isEnable : true  }).then(res => {
                var result = res.data
                if (result.success) {
                    var id = result.response.id;
                    getAreaDictionaryList({ parentId: id }).then(res => {
                        if (res.data.success) {
                            this.cityOptions = res.data.response.reverse();
                            if(oo) {
                                this.addForm.cityCode = this.needData.cityCode
                            }
                        }
                    })
                }
            })
        },
        // 市切换
        changeSearchCity(code) {
            this.regionSearchOptions = [];
            this.filters.regionCode = undefined;
            getAreaDictionaryByCode({ code: code,isEnable : true  }).then(res => {
                var result = res.data
                if (result.success) {
                    var id = result.response.id;
                    getAreaDictionaryList({ parentId: id }).then(res => {
                        if (res.data.success) {
                            this.regionSearchOptions = res.data.response.reverse();
                        }
                    })
                }
            })
        },
        // 弹窗市切换
        changeCity(code,oo) {
            this.regionOptions = [];
            this.addForm.regionCode = undefined;
            getAreaDictionaryByCode({ code: code,isEnable : true  }).then(res => {
                var result = res.data
                if (result.success) {
                    var id = result.response.id;
                    getAreaDictionaryList({ parentId: id }).then(res => {
                        if (res.data.success) {
                            this.regionOptions = res.data.response.reverse();
                            if(oo) {
                                this.addForm.regionCode = this.needData.regionCode
                            }
                        }
                    })
                }
            })
        },
        // 列表查询接口
        getDataList() {
            let params = {
                keyWord: this.filters.keyWord,
                pageSize: this.pages.pageSize,
                pageIndex: this.pages.pageIndex,
                provinceCode: this.filters.provinceCode,
                cityCode: this.filters.cityCode,
                regionCode: this.filters.regionCode,
                expertType: this.filters.expertType,
                isretrialItems: this.filters.isretrialItems,
                isChapter: this.filters.isChapter,
            }
            this.listLoading = true
            getExpertDataDictRelationList(params).then(res => {
                if (res.data.success) {
                    this.tableData = res.data.response.data;
                    this.pages.dataCount = res.data.response.dataCount;
                }
                this.listLoading = false
            })
        },
        // 新建
        handleAdd() {
            this.addDialogShow = true;
            this.addLoading = false;
            this.dialogTitle = '新增';
            this.addForm = {
                id: undefined,
                ddId: "", //专家类型
                expertExpertiseId: [],
                isChapter: "",
                ddExpertExperienceType: 173,
                provinceCode: "",
                cityCode: "",
                regionCode: "",
                remarks: "",
                userInfoId: ""
            }
        },
        addDialogClose() {
            this.addDialogShow = false;
        },
        handleEdit() {
            if (!this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            this.addDialogShow = true;
            this.addLoading = false;
            this.dialogTitle = '编辑';
            this.addForm = {
                name: this.needData.userName,
                id: this.needData.id,
                ddId: this.needData.ddId, //专家类型
                expertExpertiseId: this.needData.expertiseList, // this.needData.expertExpertiseId,
                isChapter: this.needData.isChapter,
                ddExpertExperienceType: this.needData.ddExpertExperienceType,
                provinceCode: this.needData.provinceCode,
                cityCode: this.needData.cityCode,
                regionCode: this.needData.regionCode,
                remarks: this.needData.remarks,
                userInfoId: this.needData.userInfoId,
                phone: this.needData.phone
            }
            var newArr = []
            this.needData.expertiseList.forEach(item => {
                newArr.push(item.ddId)
            });
            this.addForm.expertExpertiseId = newArr
            this.changeProvince(this.needData.provinceCode,true)
            this.changeCity(this.needData.cityCode,true)
        },
        // 新建提交
        addSubmit() {
            this.$refs.addFormRef.validate((valid) => {
                if (valid) {
                    if (this.addForm.id === undefined) {
                        let params = {
                            ...this.addForm
                        }
                        if (JSON.parse(localStorage.getItem("user")).id > 0) {
                            params.createUserId = JSON.parse(localStorage.getItem("user")).id
                        } else {
                            this.$message({
                                message: "用户信息为空，先登录",
                                type: "error"
                            });
                            _this.$router.replace(
                                "/"
                            );
                            return
                        }
                        this.addLoading = true
                        addExpertDataDictRelation(params).then(res => {
                            if (res.data.success) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success"
                                });
                                this.getDataList();
                                this.addDialogClose();
                            } else {
                                this.$message({
                                    message: res.data.message,
                                    type: "error"
                                });
                            }
                            this.addLoading = false
                        })
                    } else {
                        this.addLoading = true
                        updateExpertDataDictRelation(this.addForm).then(res => {
                            if (res.data.success) {
                                this.$message({
                                    message: res.data.message,
                                    type: "success"
                                });
                                this.getDataList();
                                this.addDialogClose();
                            } else {
                                this.$message({
                                    message: res.data.message,
                                    type: "error"
                                });
                            }
                            this.addLoading = false
                        })
                    }
                }
            })
        },
        // 删除意见
        handleDel(item) {
            this.handleDeleteVisible = true;
            this.deleteLoading = false
        },
        confirmDelete() {
            if (!this.needData || !this.needData.id) {
                this.$message({
                    message: '请选择一条数据',
                    type: "error"
                });
                return
            }
            this.deleteLoading = true
            deleteExpertDataDictRelation({ id: this.needData.id }).then(res => {
                if (res.data.success) {
                    this.handleDeleteVisible = false;
                    this.$message({
                        message: res.data.message,
                        type: "success"
                    });
                    this.getDataList();
                } else {
                    this.$message({
                        message: res.data.message,
                        type: "error"
                    });
                }
                this.deleteLoading = false
            })
        },
        querySearchAsync(queryString, cb) {
            getUserListPage({ userName: queryString, pageSize: 5, pageIndex: 1 }).then(res => {
                if (res.data.success) {
                    this.userList = res.data.response.data;
                    let userList = this.userList;
                    let results = queryString ? userList.filter(this.createStateFilter(queryString)) : userList;
                    cb(results);
                }
            })
        },
        createStateFilter(queryString) {
            return (state) => {
                return (state.name.indexOf(queryString) === 0);
            };
        },
        handleSelect(item) {
            this.$set(this.addForm, "phone", item.phoneNumber);
            this.addForm.userInfoId = item.id;
            this.addForm.provinceCode = item.provinceCode;
            if(item.provinceCode){
                this.setDefaultAreaData(item.provinceCode, true, item.cityCode)
                if(item.cityCode){
                    this.setDefaultAreaData(item.cityCode, false, item.districtCode)
                }
            }
        },
        setDefaultAreaData(code, isCity, subCode) {
            getAreaDictionaryByCode({ code: code, isEnable : true  }).then(res => {
                var result = res.data
                if (result.success) {
                    var id = result.response.id;
                    getAreaDictionaryList({ parentId: id }).then(res => {
                        if (res.data.success) {
                            if(isCity){
                                this.cityOptions = res.data.response.reverse();
                                this.addForm.cityCode = subCode;
                            }else{
                                this.regionOptions = res.data.response.reverse();
                                this.addForm.regionCode = subCode;
                            }
                        }
                    })
                }
            })
        },
        // 点击table某一行
        currentChange(val) {
            this.needData = val
        },
        // 分页
        handleCurrentChange(val) {
            this.pages.pageIndex = val;
            this.getDataList();
        },
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getDataList();
        },
        // 时间格式化
        formatCreateTime: function (row, column) {
            return !row.NoticeTime || row.NoticeTime == ""
            ? ""
            : util.formatDate.format(new Date(row.NoticeTime), "yyyy-MM-dd hh:mm");
        },
        // 表格数据分页条数累计
        indexMethod(index) {
            var currentPage = this.pages.pageIndex
            var pageSize = this.pages.pageSize
            return (index + 1) + (currentPage - 1) * pageSize
        },
		
		// 任务项目明细
		viewsProjectDetail(row) {
		    this.ProjectDetailDialog = true,
		    this.ProjectDetailLoading = true,
		    this.ProjectDetailData = []
		    var params = {
		        userId: row.userInfoId
		    }
		    getUnFinishTaskListByUser(params).then(res => {
		        var result = res.data
		        if(result) {
		            this.ProjectDetailData = result.response;
		        } else {
		            this.$message.error("加载失败！")
		        }
		        this.ProjectDetailLoading = false
		    })
		},
		// 列表样式
		rowClass(){
		    return 'padding:8px!important;'
		},
    }
}
</script>

<style lang="stylus" scoped>

</style>
